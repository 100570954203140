.skill_container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;

    
}

.skill_container > div {
    padding: 2.4rem 5rem;
    border-radius: 2rem;
    border: 1px solid ;
    background-color: rgb(0, 0, 0);
    border: 1px;
    
}

.skill_container >div:hover{
    cursor: default;
}

.skill_container > div h3{
    text-align: center;
    margin-bottom: 2rem;
    color:white;
}

.skill_content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 2rem;
}

.skill_detail{
    display: flex;
    gap: 1rem;
}

.skill_detail-icon{
    margin-top: 3px;
}

@media screen and (max-width: 1024px){
    .skill_container{
        grid-template-columns: 1fr;
    }
    .skill_container > div {
        width: 80%;
        padding: 2rem;
        margin: 0 auto;
        
    }
    .skill_content{
        padding: 1rem;
    }
}

@media screen and (max-width: 600px){
    .skill_container{
        gap: 1rem;
        margin-left: -2.55rem;
        
    }
    .skill_container > div{
        width: 106%;   
        padding: 2rem 1rem;
    }
}

